import React from 'react';
import { useMsal } from '@azure/msal-react';

const SignOutButton: React.FC = () => {
  // Use authentication context
  const { instance } = useMsal();

  const handleLogout = (logoutType?: String) => {
    switch (logoutType) {
      // Optional: logout via popup
      case 'redirect':
        instance.logoutPopup({
          postLogoutRedirectUri: '/',
          mainWindowRedirectUri: '/'
        });
        break;

      // Default: logout via redirect
      default:
        instance.logoutRedirect({
          postLogoutRedirectUri: '/'
        });
    }
  };

  return <button onClick={() => handleLogout()}>Sign out</button>;
};

export { SignOutButton };
