import React, { useEffect, useState } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';

import { loginRequest } from '../authConfig';

const RouteGuard: React.FC<RouteGuardProps> = ({ children, authorizedRoles }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { instance } = useMsal();

  const account = instance.getActiveAccount();
  const authRequest = { ...loginRequest };

  // Evaluate user authorization based on assigned role
  useEffect(() => {
    setIsAuthorized(authorizedRoles.some(role => account?.idTokenClaims?.roles?.includes(role)));
  }, [account, authorizedRoles]);

  return (
    <>
      <MsalAuthenticationTemplate authenticationRequest={authRequest} interactionType={InteractionType.Redirect}>
        {(() => {
          // Not authorized
          if (!isAuthorized) return <>You are not authorized to access this page.</>;

          // Authorized
          return <>{children}</>;
        })()}
      </MsalAuthenticationTemplate>
    </>
  );
};

export { RouteGuard };

type RouteGuardProps = {
  authorizedRoles: string[];
  children: React.ReactNode;
};
