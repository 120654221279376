import React from 'react';
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import './App.css';
import { Construct } from './components/Construct';

function App() {
  // Auto-initiate login flow on load
  // useMsalAuthentication(InteractionType.Redirect);

  return (
    <div className="App">
      <Construct />
    </div>
  );
}

export default App;
