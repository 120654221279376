import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

const SignInButton: React.FC = () => {
  // Use authentication context
  const { instance } = useMsal();

  const handleLogin = (loginType?: String) => {
    switch (loginType) {
      // Optional: login via popup
      case 'redirect':
        instance.loginPopup(loginRequest).catch(e => {
          console.error(e);
        });
        break;

      // Default: login via redirect
      default:
        instance.loginRedirect(loginRequest).catch(e => {
          console.error(e);
        });
    }
  };

  return <button onClick={() => handleLogin()}>Sign in</button>;
};

export { SignInButton };
