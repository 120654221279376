import React, { useEffect } from 'react';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';

import { RouteGuard } from './RouteGuard';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';
import { appRoles } from '../authConfig';

const Construct: React.FC<ConstructProps> = ({ children }) => {
  const account = useAccount();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated(
    account
      ? {
          homeAccountId: account.homeAccountId,
          localAccountId: account.localAccountId,
          username: account.username
        }
      : undefined
  );

  // Handle login events
  useEffect(() => {
    // Enable events for account storage events (ACCOUNT_ADDED, ACCOUNT_REMOVED)
    // instance.enableAccountStorageEvents();

    // Register event callback on component mount
    const callbackId = instance.addEventCallback((message: EventMessage) => {
      // DEBUG: Display full event object data
      console.debug(message);

      switch (message.eventType) {
        // Do something on successful user login
        case EventType.LOGIN_SUCCESS:
          // Mark newly logged in user as active account
          let payload = message.payload as AuthenticationResult;
          console.debug(payload);
          instance.setActiveAccount(payload.account);

          // Example for further action: Persist relevant information from active account to app state (if deemed necessary)

          break;

        // Do something on successful user logout
        case EventType.LOGOUT_SUCCESS:
          break;
      }
    });

    return () => {
      // Disable events for account storage events
      // instance.disableAccountStorageEvents();

      // Unregister event callback on component unmount
      if (callbackId) instance.removeEventCallback(callbackId);
    };
  }, [instance]);

  // Not authenticated
  if (!isAuthenticated) {
    return (
      <>
        <p>You are not signed in.</p>
        <SignInButton />
      </>
    );
  }

  // Authenticated
  return (
    <>
      <h2>You are signed in as</h2>
      <h3>Username</h3>
      <p>{account ? account.username : 'UNKNOWN'}</p>
      <h3>Assigned Role</h3>
      <p>{account?.idTokenClaims?.roles ? account.idTokenClaims.roles.join(', ') : 'UNKNOWN'}</p>

      <SignOutButton />

      {/* Role-based access control (RBAC) */}
      <h2>Content for all roles</h2>
      <RouteGuard authorizedRoles={[appRoles.Admin, appRoles.User]}>
        <p>ok</p>
      </RouteGuard>

      <h2>Content for Admin role</h2>
      <RouteGuard authorizedRoles={[appRoles.Admin]}>
        <p>ok</p>
      </RouteGuard>

      <h2>Content for User role</h2>
      <RouteGuard authorizedRoles={[appRoles.User]}>
        <p>ok</p>
      </RouteGuard>

      {children}
    </>
  );
};

export { Construct };

type ConstructProps = {
  children?: React.ReactNode;
};
