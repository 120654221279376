const appRoles = {
  Admin: 'Admin',
  User: 'User'
};

const loginRequest = {
  scopes: ['User.Read']
};

const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}`,
    clientId: `${process.env.REACT_APP_MSAL_CLIENT_ID}`,
    redirectUri: `${process.env.REACT_APP_MSAL_REDIRECT_URL}`,
    tenantId: `${process.env.REACT_APP_MSAL_TENANT_ID}`
  },
  cache: {
    cacheLocation: 'localStorage', // enables shared login status between tabs (however, sessionStorage would be more secure)
    storeAuthStateInCookie: false
  }
};

export { appRoles, loginRequest, msalConfig };
